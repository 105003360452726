<template>
  <!-- 总决赛列表 -->
  <div class="bigScreen">
    <ScaleBox>
      <div class="main-wraper">
        <div class="contentBox">
          <span class="box1"></span>
          <span class="box2"></span>
          <span class="box3"></span>
          <span class="box4"></span>
          <div class="tableBox">
            <div class="titleBox">
              <div class="logoBox"></div>
              <div class="midBox">
                <div class="midLeftBox">
                  <p class="topBox">2022年成都市青少年气步枪射击锦标赛</p>
                  <p class="buttomBox">男子组10米气步手枪决赛</p>
                </div>
              </div>
              <div class="rightBox">
                <span class="sjBox"></span>
                总决赛
              </div>
            </div>
            <div class="tableConBox">
              <div class="tBox">
                <div>排名</div>
                <div>城市</div>
                <div>场地</div>
                <div>靶位</div>
                <div>选手</div>
                <div>单位</div>
                <div>总得分</div>
              </div>
              <div class="bBox">
                <div
                  class="rowBox"
                  v-for="(item, index) in tableData"
                  :key="index"
                >
                  <div>{{ index + 1 }}</div>
                  <div>{{ item.siteCity }}</div>
                  <div>{{ item.siteName }}</div>
                  <div>{{ item.name }}</div>
                  <div>{{ item.address }}</div>
                  <div>{{ item.sum3 }}</div>
                  <div>{{ item.sum }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScaleBox>
  </div>
</template>

<script>
import ScaleBox from '../../components/ScaleBox/index.vue'
export default {
  name: 'bigScreen',
  components: {
    ScaleBox
  },
  props: {},
  data() {
    return {
      tableData: []
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.threeGo();
  },
  methods: {
    //30秒后进入跳转页面
    threeGo() {
      const TIME_COUNT = 30;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.$router.push({
              path: './championships'
            });
          }
        }, 1000)
      }
    },

    // ----------------------------------------
    // 设置表格第三列文字为左对齐
    // eslint-disable-next-line no-unused-vars
    cellStyle({ row, rowIndex }) {
      // 第n(3)列的时候，才改变
      // console.log(rowIndex);
      if (rowIndex === 0) {
        return `colStyle colStyle1`;
      } else if (rowIndex === 1) {
        return `colStyle colStyle2`;
      } else if (rowIndex === 2) {
        return `colStyle colStyle3`;
      } else {
        return `colStyle`;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bigScreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  background-image: url("../../assets/sjbj.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .main-wraper {
    position: relative;
    user-select: none;
    width: 1920px;
    height: 1080px;
    margin: 0 auto;
    .contentBox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      width: 1462px;
      height: 815px;
      // box-sizing: border-box;
      border: 3px solid #ffffff;
      .box1 {
        position: absolute;
        top: -3px;
        left: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-top: 3px solid #010945;
        border-left: 3px solid #010945;
      }
      .box2 {
        position: absolute;
        top: -3px;
        right: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-top: 3px solid #010945;
        border-right: 3px solid #010945;
      }
      .box3 {
        position: absolute;
        bottom: -3px;
        left: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-bottom: 3px solid #010945;
        border-left: 3px solid #010945;
      }
      .box4 {
        position: absolute;
        bottom: -3px;
        right: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-bottom: 3px solid #010945;
        border-right: 3px solid #010945;
      }
      .tableBox {
        box-sizing: border-box;
        background: transparent;
        padding: 15px 16.5px;
        .titleBox {
          width: 1429px;
          height: 124px;
          display: flex;
          background: #010945;
          border: 3px solid #205090;
          box-sizing: border-box;
          .logoBox {
            width: 83px;
            height: 85px;
            margin: 22px 32px 17px 41px;
            background-image: url("../../assets/logo1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            // border: 1px dashed #fff;
          }
          .midBox {
            display: flex;
            width: 1079px;
            margin-top: 15px;
            // margin-right: 368px;
            .midLeftBox {
              width: 676px;
              margin-right: 169px;
              .topBox {
                font-size: 36px;
                font-family: MFJianHei_Noncommercial-Regular,
                  MFJianHei_Noncommercial;
                font-weight: 600;
                color: #ffffff;
                margin: 0;
              }
              .buttomBox {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                margin: 0;
              }
            }
          }
          .rightBox {
            position: relative;
            width: 219px;
            height: 114px;
            background: #cc834e;
            margin: 5px;
            font-size: 32px;
            font-family: MFJianHei_Noncommercial-Regular,
              MFJianHei_Noncommercial;
            font-weight: 400;
            color: #ffffff;
            line-height: 114px;
            text-align: center;
            .sjBox {
              position: absolute;
              top: 0;
              left: -1px;
              display: inline-block;
              /*宽高为0*/
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 114px 62px 0px 0px;
              border-color: #010945 transparent transparent transparent;
            }
          }
        }
        .tableConBox {
          width: 1429px;
          height: 661px;
          border: 3px solid #205090;
          border-top: none;
          box-sizing: border-box;
          .tBox {
            width: 1423px;
            height: 63px;
            display: flex;
            background: #010945;
            opacity: 0.8;
            border: none;
            border-bottom: 3px solid #205090;
            box-sizing: border-box;
            div {
              flex: 1;
              height: 63px;
              font-size: 26px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
              line-height: 63px;
            }
          }
          .bBox {
            width: 1423px;
            height: 596px;
            background: #ffffff;
            opacity: 0.8;
            .rowBox {
              height: 75px;
              display: flex;
              border-bottom: 3px solid #205090;
              box-sizing: border-box;
              div {
                flex: 1;
                height: 75px;
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #06206b;
                text-align: center;
                line-height: 75px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            .rowBox:nth-child(1) {
              background: rgba(235, 188, 9, 0.9);
            }
            .rowBox:nth-child(2) {
              background: rgba(233, 197, 58, 0.8);
            }
            .rowBox:nth-child(3) {
              background: rgba(238, 212, 112, 0.7);
            }
          }
        }
      }
    }
  }
}
</style>
